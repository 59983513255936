import React, { forwardRef, useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// NEW
import { Button, Divider, List, Modal, Progress, Result } from 'antd';

// css
import { css } from 'emotion';
import common from '../../style/common';
import colors from '../../style/colors';
import ScrollView from '../ui/ScrollView';
import InlineSpinner from '../ui/InlineSpinner';
import { toggleWorkHourModal } from '../../actions/uiActions';

import req from '../../utilities/request-utility';

const SubmitAllComponent = (props, ref) => {
	const {
		approvedTimeRecords = [],
		setApprovedTimeRecords = () => {},
		setIsSubmittingAll = () => {},
		selectedUser = '',
		setShowSubmitAllComponent = () => {},
		needsProcessingIsFetching = false,
		needsProcessingRefetch = () => {}
	} = props;
	const dispatch = useDispatch();
	const isMobile = useMediaQuery({ maxWidth: '800px' });
	const modal = useSelector(({ ui }) => ui.workHourModal);

	const [successfullySubmittedData, setSuccessfullySubmittedData] = useState([]);
	const [submissionStatusData, setSubmissionStatusData] = useState({
		status: 'waiting',
		message: '',
		percentage: 0
	});

	const handleSubmit = async () => {
		try {
			//format data
			const formattedApprovedRecords = approvedTimeRecords.map(record => ({
				recordID: record.id,
				type: record.type,
				data: record.data
			}));

			let submittedRecords = 0;
			for (const record of formattedApprovedRecords) {
				const { data, type, recordID } = record;

				const currentDate =
					type === 'time'
						? data.date
						: `${moment(data.startDate).format('YYYY-MM-DD')} - ${moment(data.endDate).format(
								'YYYY-MM-DD'
						  )} `;

				setSubmissionStatusData({
					status: 'submitting',
					message: `Submitting ${currentDate} (ID:${record.recordID})`,
					percentage: Math.round((submittedRecords / formattedApprovedRecords.length) * 100)
				});

				await req().put(`semcotime/foremen-teams/users/${selectedUser}/work-hours/${recordID}`, {
					...data,
					action: 'submit'
				});

				submittedRecords++;
				setSuccessfullySubmittedData(successfullySubmittedData => [
					...successfullySubmittedData,
					{
						date: currentDate,
						id: recordID,
						type
					}
				]);

				if (submittedRecords === formattedApprovedRecords.length) {
					setSubmissionStatusData({
						status: 'success',
						message: `Successfully submitted all Approved Records`,
						percentage: 100
					});
					setIsSubmittingAll(false);
					needsProcessingRefetch();
				}
			}
		} catch (error) {
			console.log('error: ', error);
			let errorMessage = 'Unspecified error';
			if (error && error.response && error.response.data) {
				if (error.response.data.message) errorMessage = error.response.data.message;
				else errorMessage = error.response.statusText;
			}

			setSubmissionStatusData(submissionStatusData => ({
				...submissionStatusData,
				message: `${submissionStatusData.message.split(' ')[1]} ${
					submissionStatusData.message.split(' ')[2]
				} | Error: ${errorMessage}`,
				status: 'error'
			}));
			setIsSubmittingAll(false);
		}
	};

	const hanldeRetry = async () => {
		await needsProcessingRefetch();
		setSubmissionStatusData({
			status: 'waiting',
			message: '',
			percentage: 0
		});

		//reset the approvedTimeRecord array
		setApprovedTimeRecords(approvedTimeRecords.filter(record => !successfullySubmittedData.includes(record.id)));
		setSuccessfullySubmittedData([]);
	};

	const { message = '', status = 'waiting', percentage = 0 } = submissionStatusData;

	const progressBarStatus = { success: 'success', error: 'exception', submitting: 'active' }[status] || '';

	const resultIconStatus = { success: 'success', error: 'error' }[status] || '';

	const renderSubmitAllComponent = () => {
		return (
			<div className={componentStyles(isMobile)}>
				<ScrollView
					className={scrollViewStyles({
						topBarHeight: common.topBarHeight,
						bottomPanelHeight: 0
					})}>
					{approvedTimeRecords.length == 0 && (
						<Result
							status="warning"
							title={'No Approved Records Selected'}
							subTitle={'Please select or approve records to continue'}
						/>
					)}

					{status === 'waiting' && !approvedTimeRecords.length == 0 && (
						<Result
							title={`Selected ${approvedTimeRecords.length} Approved Record/s`}
							subTitle={'Press "Submit All" to continue'}
							extra={
								<Button
									onClick={() => {
										setIsSubmittingAll(true);
										handleSubmit();
									}}
									type="primary"
									key="console">
									Submit All
								</Button>
							}
						/>
					)}
					{status !== 'waiting' && !approvedTimeRecords.length == 0 && (
						<>
							<Result
								icon={status === 'submitting' ? <InlineSpinner /> : ''}
								status={resultIconStatus}
								subTitle={message}
								extra={
									<>
										<Progress percent={percentage} status={progressBarStatus} />
										{status === 'success' && (
											<Button
												type="primary"
												onClick={() => setShowSubmitAllComponent(false)}
												key="console">
												OK
											</Button>
										)}
										{status === 'error' && (
											<Button
												type="primary"
												loading={needsProcessingIsFetching}
												onClick={hanldeRetry}
												key="console">
												Retry
											</Button>
										)}
									</>
								}
							/>

							<Divider orientation="left">Successfully Submitted</Divider>
							<List
								size="small"
								bordered
								dataSource={successfullySubmittedData}
								renderItem={item => (
									<List.Item extra={<h4>{`${capitalize(item.type)}`}</h4>}>
										<List.Item.Meta title={item.date} description={`[${item.id}]`} />
									</List.Item>
								)}
							/>
						</>
					)}
				</ScrollView>
			</div>
		);
	};

	return (
		<>
			{!isMobile && renderSubmitAllComponent()}

			{isMobile && (
				<Modal
					centered
					closable={false}
					footer={[
						<Button key="back" onClick={() => dispatch(toggleWorkHourModal(false))}>
							Cancel
						</Button>
					]}
					visible={modal}>
					{renderSubmitAllComponent()}
				</Modal>
			)}
		</>
	);
};

const scrollViewStyles = ({ bottomPanelHeight, topBarHeight }) => css`
	height: calc(96vh - ${topBarHeight}px - ${bottomPanelHeight}px);
	padding: 1rem;

	@media (max-width: 414px) {
		padding: unset;
	}
`;

const componentStyles = (primaryColor, isMobile) => css`
	background-color: ${colors.white};
	border-radius: 3px;
	border: ${isMobile ? 'none' : `1px ${colors.midGrey} solid`};
	border-left: ${isMobile ? 'none' : `3px ${primaryColor} solid`};
	padding: 0.65rem;
	margin-bottom: 10px;
`;

export default forwardRef(SubmitAllComponent);
